@value color-white from "./../../styles/_variables.css";

.container {
	width: 100%;
	height: 150px;
	border-radius: 10px;
	overflow: hidden;
}

.container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}