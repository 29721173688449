@value color-grey: #3F3F3F;
@value color-midgrey: #656565;
@value color-mildgrey: #DBD9D3;
@value color-lightgrey: #EEEEEE;
@value color-lightergrey: #F5F5F5;
@value color-white: #FFFFFF;
@value color-pink: #FF6E75;
@value color-green: #76B8AF;

@value color-comment-border: #D3D3D3;
@value color-comment-bg: #F7F7F7;

@value height-header: 52px;
@value height-nav: calc(64px + env(safe-area-inset-bottom));

.font-bold {
    font-family: "Rubik Medium", "Rubik", sans-serif;
    font-weight: 500;
}