@value color-lightgrey from "./../../styles/_variables.css";

@keyframes loading {
	from {opacity: 0.5;}
	to {opacity: 1;}
}

.stripe {
	background: color-lightgrey;
	height: 6px;
	animation-name: loading;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}

.icon {
	background: color-lightgrey;
	width: 30px;
	height: 30px;
	margin-bottom: 5px;
}

.title {
	margin-bottom: 20px;
}

.favourite {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-grow: 1;
}

.favourite span {
	display: inline-block;
	margin-right: 10px;
}

.favourite svg {
	width: 24px;
}

.panelIcons {
	display: flex;
	align-items: flex-start;
}

.panelIconList {
	flex-grow: 1;
}

.panelIconList > div {
	display: inline-block;
	margin-right: 15px;
}

.transport {
	text-align: right;
	flex-grow: 1;
}

.transport > div {
	display: inline-block;
}

.details {
	background: color-lightgrey;
	border-radius: 10px;
	padding: 16px;
	margin-top: 15px;
}