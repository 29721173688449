@value color-lightergrey, color-lightgrey, color-white, font-bold from "./../../styles/_variables.css";

.overlay .overlay-layer {
	top: 0;
}

.container {
	position: absolute;
	top: 0;
	left: 0;
	background: color-lightergrey;
	width: 100%;
	z-index: 40;
	border-radius: 0 0 15px 15px;
	padding: 20px;
}

.boxes {
	border-radius: 15px;
	background: color-lightgrey;
	padding: 15px;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.boxes > div {
	flex-basis: 50%;
}

.close {
	position: absolute;
	right: 10px;
	top: 10px;
	padding: 10px;
}

.close svg {
	width: 18px;
	height: 18px;
}

.checkboxWrap {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.checkbox {
	width: 40px;
	height: 40px;
	border-radius: 3px;
	background: color-white;
	margin-right: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px;
}

.checkbox svg {
	width: 100%;
	height: 100%;
}

.checkTitle {
	font-size: 16px;
	composes: font-bold;
}

.button {
	width: 100%;
	margin-bottom: 15px;
}

.cancel {
	width: 100%;
	text-align: center;
	font-size: 14px;
	text-decoration: underline;
}