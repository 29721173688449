@value color-white from "./../../styles/_variables.css";

.overlay {
	display: none;
}

.disabled > .overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 20;
	background: color-white;
	opacity: 0.6;
	display: block;
}

.loader {
	position: absolute;
	z-index: 30;
	left: 0;
	top: 0;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
}