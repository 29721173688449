@value color-white, color-lightergrey, color-lightgrey, color-mildgrey, color-grey, color-pink, font-bold from "./../../styles/_variables.css";

.avatar {
	flex-shrink: 0;
}

.avatar img, .avatar svg, .letter {
	width: 100%;
	height: 100%;
}

.avatar img {
	border-radius: 50%;
}

.letter {
	border-radius: 50%;
	background: color-white;
	display: flex;
	justify-content: center;
	align-items: center;
	composes: font-bold;
	font-size: 17px;
}

.letterLight {
	background: color-lightergrey;
}