@import-normalize;
@import "~bootstrap/scss/bootstrap-reboot.scss";
@import "~bootstrap/scss/bootstrap-grid.scss";

@import "../../styles/base/base";
@import "../../styles/base/typography";

.App {
	padding-bottom: 64px;
	min-height: 100vh;
	//overflow: hidden;
	z-index: 30;
	max-width: 480px;
}

.fixed {
	position: fixed;
	width: 100%;
}

// Quick fix to hide the bottom nav when keyboard is popped up
.not-fixed {
	position: relative;
	padding-bottom: 64px;

	nav {
		position: absolute;
	}
}