@value color-white from "./../../styles/_variables.css";

.overlay {
	display: none;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 30;
	background: color-white;
	top: 0;
	left: 0;
}