.container {
	position: absolute;
	width: calc(100% - 40px);
	top: 20px;
	left: 20px;
	border-radius: 5px;
	padding: 15px;
	z-index: 75;
	box-shadow: 0 3px 10px -2px rgba(0,0,0,0.23);
}

.success {
	color: #155724;
	background: #d4edda;
	border: 1px solid #c3e6cb;
}

.error {
	color: #a94442;
	background: #f2dede;
	border: 1px solid #ebccd1;
}

.info {
	color: #0c5460;
	background: #d1ecf1;
	border: 1px solid #bee5eb;
}

.warning {
	color: #856404;
	background: #fff3cd;
	border: 1px solid #ffeeba;
}

.close {
	position: absolute;
	right: 8px;
	top: 2px;
}

.close svg {
	width: 10px;
}