@value color-pink from "./../../styles/_variables.css";

.container {
	position: absolute;
	top: -5px;
	right: 12px;
	background: color-pink;
	width: 11px;
	height: 11px;
	border-radius: 50%;
}