body {
	font-family: "Karla", sans-serif;
	color: #3F3F3F;
	font-size: 15px;
	line-height: 22px;
}

h1, h2, h3, h4, h5, h6 {
	font-family: "Rubik Medium", "Rubik", sans-serif;
	font-weight: 500;
}

h3 {
	line-height: 26px;
}

.bold {
	font-family: "Rubik Medium", "Rubik", sans-serif;
	font-weight: 500;
}

.regular {
	font-family: "Karla", sans-serif;
	font-weight: normal;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

a {
	color: #3F3F3F;
	text-decoration: underline;
}