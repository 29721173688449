@value color-lightgrey, color-white, font-bold, height-nav from "./../../styles/_variables.css";

.container {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 45;
	display: flex;
	justify-content: center;
}

.subContainer {
	width: 100%;
	border-radius: 15px;
	background-color: color-white;
	box-shadow: 0 -2px 4px 0 rgba(0,0,0,0.25);
	padding: 60px 40px calc(height-nav + 40px) 40px;
	max-width: 480px;
	position: relative;
}

.close {
	position: absolute;
	right: 20px;
	top: 20px;
}

.close svg {
	width: 40px;
	height: 40px;
}

.row {
	padding: 18px 0;
	border-bottom: 1px solid color-lightgrey;
}

.row:last-of-type {
	border-bottom: none;
}

.rowHeading {
	//border-bottom: none;
}

.recommendations {
	overflow: hidden;
	padding-left: 30px;
}

.recommendations > .row:last-of-type {
	border-bottom: 1px solid color-lightgrey;
}

.inner {
	display: flex;
	align-items: center;
}

.icon {
	display: flex;
	justify-content: center;
	width: 26px;
	margin-right: 30px;
}

.icon svg {
	height: 23px;
	max-width: 26px;
}

.title {
	font-size: 15px;
	line-height: 17px;
	composes: font-bold;
}

.arrow {
	padding: 0 10px;
	transform: rotate(-90deg);
	margin-left: 10px;
}

.arrow svg {
	width: 20px;
	height: 20px;
}