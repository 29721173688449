@value color-white, color-lightgrey, color-pink, font-bold, color-grey from "./../../styles/_variables.css";

.button {
	min-height: 52px;
	width: 100%;
	border-radius: 10px;
	composes: font-bold;
	color: color-white;
	font-size: 16px;
	background: color-pink;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 15px;
}

.active {
	background: color-white;
	color: color-grey;
}